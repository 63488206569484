















// @ is an alias to /src
import Vue from 'vue'
import Component from 'vue-class-component'
import firebase from 'firebase'
import AuthProvider = firebase.auth.AuthProvider;

@Component({
  components: {
  }
})
export default class Login extends Vue {
  private password = ''
  private email = ''
  private errorMessage: null|string = null

  async loginGoogle (): Promise<void> {
    return this.rawLogin(new firebase.auth.GoogleAuthProvider())
  }

  private async rawLogin (provider: AuthProvider) {
    try {
      const res = await firebase.auth().signInWithPopup(provider)
      if (res.user !== null) {
        this.$router.push({ path: '/' })
      }
      this.$emit('loggedIn')
    } catch (e) {
      this.errorMessage = e.message
    }
  }
}

