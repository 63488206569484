







































// @ is an alias to /src
import Vue from 'vue'
import Component from 'vue-class-component'
import firebase from 'firebase'
import { EventBus } from '@/utils/bus'
import CopyTextComponent from '@/components/CopyTextComponent.vue'
import LoginComponent from '@/components/Login.vue'

@Component({
  components: {
    CopyTextComponent,
    LoginComponent
  }
})
export default class Home extends Vue {
  private domain = '';
  private https = true;
  private isLoggedin = false;
  private token = '';
  private loading = false;
  private couldTriggerRescan = true;
  private scanFinished = false;
  async mounted () {
    this.isLoggedin = firebase.auth().currentUser !== null
    if (this.$route.hash.length > 0) {
      const [domain, https] = this.$route.hash.substr(1).split(',')
      console.log(domain, https)
      if (domain) {
        this.domain = domain
        this.https = https === 'false'
      }
    }

    firebase.auth().onAuthStateChanged((user) => {
      this.isLoggedin = user !== null
    })
  }

  requestKey () {
    this.loading = true
    this.token = ''
    this.couldTriggerRescan = true
    this.scanFinished = false
    const ref = firebase.database().ref('apireqs').push({
      domain: this.domain,
      https: this.https
    })
    firebase.database().ref('apiresps').child(ref.key!)
      .on('value', (d) => {
        if (!d.exists()) {
          console.log('not exists')
          return
        }
        this.token = d.val().token
        this.loading = false
      })
  }

  async triggerRescan () {
    this.couldTriggerRescan = false
    this.loading = true
    const r = await fetch(`https://europe-west3-dedge-cookies.cloudfunctions.net/webReqs/generate?callback=yo&token=${this.token}&url=https://${this.domain}/`)
    console.log(await r.text())
    this.loading = false
    this.scanFinished = true
  }

  login () {
    EventBus.$emit('showLogin')
  }
}

