




























































// @ is an alias to /src
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: {

  }
})
export default class ConsentRecord extends Vue {
  private loading = false;
  private data: any = null;
  private consentid = '';

  async load () {
    function computeDurationString (duration: number) {
      const expirations = {
        year_sing: '{value} year',
        year_plu: '{value} years',
        month_sing: '{value} month',
        month_plu: '{value} months',
        day_sing: '{value} day',
        day_plu: '{value} days',
        hour_sing: '{value} hour',
        hour_plu: '{value} hours',
        minute_plu: '{value} minutes',
        minute_sing: '{value} minute',
        session: 'Session'
      }
      const oneMinute = 60
      const oneHour = oneMinute * 60
      const oneDay = oneHour * 24
      const oneMonth = oneDay * 30
      const oneYear = oneDay * 365
      const thresholds = [
        { threshold: oneYear, unit: oneYear, key: 'year' },
        { threshold: oneMonth * 3, unit: oneMonth, key: 'month' },
        { threshold: oneDay * 2, unit: oneDay, key: 'day' },
        { threshold: oneHour, unit: oneHour, key: 'hour' },
        { threshold: oneMinute, unit: oneMinute, key: 'minute' }
      ]

      for (const singleThreshold of thresholds) {
        if (duration > singleThreshold.threshold) {
          const val = Math.round(duration / singleThreshold.unit)
          return expirations[`${singleThreshold.key}_${val > 1 ? 'plu' : 'sing'}`].replace('{value}', val)
        }
      }

      return expirations.session
    }
    const strings = {
      cat_needed: 'Necessary',
      cat_needed_desc: 'Necessary cookies allow the website to behave properly enabling basic functionalities such as private area logins or the website navigation',
      cat_prefs: 'Preferences',
      cat_prefs_desc: 'Preference cookies allow to save user\'s preferences for the next visit. For example they could hold the user language.',
      cat_stats: 'Statistics',
      cat_stats_desc: 'Cookies of this kind are used to collect user\'s information about the navigation path with the end goal to analyze the statistics in an aggregated manner to enhance the website',
      cat_ads: 'Marketing and Ads',
      cat_ads_desc: 'Marketing cookies will be used mainly by third party to create a user profile to track his behaviour and habits across the web for marketing purposes.',
      cat_unknown: 'Unknown',
      cat_unknown_desc: 'Unknown cookies, as the name implies, are still unknown to our system.'
    }
    this.loading = true
    this.data = null
    const resp = await fetch('https://europe-west3-dedge-cookies.cloudfunctions.net/webReqs/consent-storage/' + this.consentid)
    const json = await resp.json()
    if (json.success) {
      const consentClasses = json.data.consentClasses
      let tmp = json.data.cookieDefinition
      const firstBracket = tmp.indexOf('(')
      tmp = tmp.substr(firstBracket + 1)
      tmp = tmp.substr(0, tmp.length - 2)
      const parsed = JSON.parse(tmp)
      const date = new Date(json.data.timestamp).toString()
      this.data = {
        consentClasses: consentClasses.map((cat) => { return { name: strings['cat_' + cat], description: strings['cat_' + cat + '_desc'] } }),
        cookies: parsed.cookies.map((cookie) => { cookie.duration = computeDurationString(cookie.duration); return cookie }),
        website: json.data.domain,
        hash: json.data.hashOfUserAgent,
        date
      }
    }
    this.loading = false
  }
}

