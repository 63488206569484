import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import firebase from 'firebase'
import moment from 'moment'
import Decimal from 'decimal.js'

firebase.initializeApp({
  apiKey: 'AIzaSyB51PK-bBqwAXZagGEBWmbKvI0YcHZIdWk',
  authDomain: 'dedge-cookies.firebaseapp.com',
  databaseURL: 'https://dedge-cookies.firebaseio.com',
  projectId: 'dedge-cookies',
  storageBucket: 'dedge-cookies.appspot.com',
  messagingSenderId: '979934722927',
  appId: '1:979934722927:web:dad456d62a41b828a6069a'
})

Vue.prototype.$EventBus = new Vue()
Vue.config.productionTip = false

const unsub = firebase.auth().onAuthStateChanged(user => {
  const app = new Vue({
    router,
    render: h => h(App)
  })
  app.$mount('#app')
  unsub()
})
