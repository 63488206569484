



















import Vue from 'vue'
import Component from 'vue-class-component'
import LoginComponent from '@/components/Login.vue'
import firebase from 'firebase'
import { EventBus } from '@/utils/bus'

declare var bootstrap

@Component({
  name: 'Header',
  components: { LoginComponent }
})
export default class Header extends Vue {
  private modal: any;
  private isLoggedin = false;
  async mounted () {
    this.modal = new bootstrap.Modal(this.$refs.loginmodal, {})
    this.isLoggedin = firebase.auth().currentUser !== null

    firebase.auth().onAuthStateChanged((user) => {
      this.isLoggedin = user !== null
    })

    EventBus.$on('showLogin', () => this.modal.show())
  }

  login () {
    this.modal.show()
  }

  async logout () {
    await firebase.auth().signOut()
    this.$router.push({ path: '/' })
  }

  closeModal () {
    this.modal.hide()
  }
}
