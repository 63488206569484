import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import firebase from 'firebase'
Vue.use(VueRouter)

export const authGuard = (to, from, next) => {
  if (firebase.auth().currentUser === null) {
    return next('/')
  }
  return next()
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'd-edge Macaron Login Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/consentrecords',
    name: 'd-edge Macaron Consent Record Viewer',
    component: () => import(/* webpackChunkName: "home" */ '../views/ConsentRecords.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

export default router
