











import Vue from 'vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Component from 'vue-class-component'
declare var bootstrap

@Component({
  components: { Header, Footer }
})
export default class App extends Vue {

}

