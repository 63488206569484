







import Vue from 'vue'
import Component from 'vue-class-component'
import firebase from 'firebase'
declare var bootstrap: any

@Component({})
export default class CopyTextComponent extends Vue {
  private tt;

  mounted () {
    const btnEl: any = this.$refs.copyBtn
    this.tt = new bootstrap.Tooltip(btnEl, { trigger: 'manual' })
  }

  copy () {
    const el: any | undefined = this.$slots.default![0].elm
    if (typeof (el) === 'undefined') {
      return
    }
    const wasDisabled = !!el.getAttribute('disabled')
    if (wasDisabled) {
      el.removeAttribute('disabled')
    }
    el.select()
    el.setSelectionRange(0, 99999)

    if (wasDisabled) {
      el.setAttribute('disabled', 'disabled')
    }
    const res = document.execCommand('copy')
    const sel = window.getSelection()
    if (sel !== null) {
      sel.removeAllRanges()
    }

    if (res) {
      this.tt.show()
      setTimeout(() => this.tt.hide(), 2000)
    }
  }
}
